.pre {
    position: relative;
    padding: 0;
    margin: 0;
    width: calc(100vw - 1rem);
    max-width: 40rem;
    min-width: 25rem;
}

.box {
    width: 100%;
    max-height: 10rem;
    background: #eee;
    border-radius: 4px;
    padding: 0;
    margin: 0;
    display: flex;
    overflow-x: scroll;
    overflow-y: scroll;
    flex-direction: column-reverse;
    resize: vertical;
}

.box::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.box::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
}

.box::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 4px;
}

.box::-webkit-scrollbar-corner {
    background: transparent;
    border-radius: 4px;
}

.box::-webkit-resizer {
    display: none;
}

.code {
    margin: .5rem;
    padding: 0;
    font-family: Roboto Mono, monospace;
    font-weight: 300;
}